import Http from '@app/api/http';
import * as Interceptors from '@app/api/interceptors/sharedInterceptors';
import { NEXT_PUBLIC_QVEDO_MAP_CATALOG_HOST, NEXT_PUBLIC_QVEDO_ROOT_DOMAIN } from '@app/constants';
import { getCookie, removeCookies, setCookies } from 'cookies-next';
import { IInitAuth, initAuth } from './auth/initAuth';
import { ILoginGeo } from '@main/src/types/geo';
import { ApiMethods } from '@app/api/http/types';

export const authServiceBrowser: IInitAuth = initAuth({
	baseUrl: NEXT_PUBLIC_QVEDO_MAP_CATALOG_HOST,
	storageAdapter: {
		get: (name: string): string | undefined | null => {
			return getCookie(name)?.toString();
		},
		remove: name => {
			removeCookies(name, { domain: NEXT_PUBLIC_QVEDO_ROOT_DOMAIN });
		},
		set: (name, value) => {
			setCookies(name, value, {
				domain: NEXT_PUBLIC_QVEDO_ROOT_DOMAIN,
				expires: new Date(Date.now() + 86400e6),
			});
		},
	},
});

export let loginGeo: ILoginGeo | null = null;
export let CURRENT_LOCALE = 'ru';
export interface IMakeBrowserHttpClient {
	loginGeoData: ILoginGeo;
	locale?: string;
}

export let browserHttpApi: Http | null = null;
export const makeBrowserHttpClient = ({ loginGeoData, locale }: IMakeBrowserHttpClient) => {
	if (locale) {
		CURRENT_LOCALE = locale;
	}
	loginGeo = loginGeoData;
	browserHttpApi = new Http({
		baseURL: NEXT_PUBLIC_QVEDO_MAP_CATALOG_HOST,
		withCredentials: true,
	});

	browserHttpApi.addInterceptors(Interceptors);
};

export const getBrowserHttpClient = async (): Promise<ApiMethods> => {
	if (browserHttpApi === null) {
		browserHttpApi = new Http({
			baseURL: process.env.NEXT_PUBLIC_QVEDO_MAP_CATALOG_HOST,
			withCredentials: true,
		});

		browserHttpApi.addInterceptors(Interceptors);
	}

	const hasLoggedIn = await authServiceBrowser?.checkLoggedIn();
	if (!hasLoggedIn) {
		if (authServiceBrowser?.hasAnonymous()) {
			await authServiceBrowser?.login({
				auth_type: 'device',
				lang: CURRENT_LOCALE as LangCodeForUI,
			});
		} else {
			await authServiceBrowser?.refresh({
				lang: CURRENT_LOCALE as LangCodeForUI,
			});
		}
	} else if (authServiceBrowser?.hasEmptyGeo()) {
		await authServiceBrowser?.refresh({
			lang: CURRENT_LOCALE as LangCodeForUI,
		});
	}

	return browserHttpApi.api;
};
