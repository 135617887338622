import { ModuleInterceptor } from '../http/types';
import { IS_DEV } from '@app/constants';
import { captureException } from '@sentry/core';

export default (): ModuleInterceptor => ({
	request: config => {
		if (IS_DEV && config && config.method && config.url) {
			// eslint-disable-next-line no-console
			console.log(`[${config.method} ${config.url}] send`);
		}

		return config;
	},
	requestError: error => {
		captureException(error);
		return Promise.reject(error);
	},

	response: data => {
		return data;
	},
	responseError: (error: any) => {
		// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/restrict-template-expressions,no-console
		console.log(`[${error.config.method} ${error.config.url} ${error.status}] error`, {
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
			statusText: error.statusText,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
			status: error.status,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
			headers: error.config.headers,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
			response: error.data,
		});
		captureException(error);
		return Promise.reject(error);
	},
});
