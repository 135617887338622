import { ModuleInterceptor } from '../http/types';
import { v4 as uuidv4 } from 'uuid';

export default (): ModuleInterceptor => ({
	request: config => {
		if (config?.headers) {
			// eslint-disable-next-line @typescript-eslint/ban-ts-comment
			// @ts-ignore
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			config.headers.common['x-request-id'] = `web-${typeof window === 'undefined' ? 'node' : 'browser'}-${uuidv4()}`;
		}

		return config;
	},
	requestError: error => Promise.reject(error),

	response: data => data,
	responseError: error => Promise.reject(error),
});
