export const COOKIE_LOCALE = 'qvedoLocale';
export const COOKIE_NEXT_LOCALE = 'NEXT_LOCALE';
export const COOKIE_CURRENCY = 'qvedoCurrency';
export const COOKIE_CHOSEN_DATE = 'qvedoDateFilter';
export const COOKIE_PERMISSION = 'qvedoPermissionCookie';
export const COOKIE_MOBILE_ONBOARD = 'qvedoMobileOnboard';

export const COOKIE_X_VISITOR_ID = 'q_x_visitor_id';
export const COOKIE_X_VISITOR_SESSION = 'q_x_visitor_session';
export const COOKIE_X_VISITOR_DATA = 'q_x_visitor_data';

export const COOKIE_MAP_SESSION = 'q_m_session';
