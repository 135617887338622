const NOT_TRANSLATION = ['de', 'es', 'fr', 'it', 'th'];

module.exports = {
	locales: ['de', 'en', 'es', 'fr', 'it', 'ru', 'th'],
	defaultLocale: 'ru',
	fallbackLng: {
		de: ['en'],
		fr: ['en'],
		it: ['en'],
		th: ['en'],
		es: ['en'],
		default: ['ru'],
	},

	loadLocaleFrom: (lang, ns) => {
		const locale = NOT_TRANSLATION.includes(lang) ? 'en' : lang;

		return Promise.all([
			import(`./locales/${locale}/${ns}.json`)
				.then(m => m.default)
				.catch(() => {
					return {};
				}),
			import(`./shared/locales/${locale}/${ns}.json`)
				.then(m => m.default)
				.catch(() => {
					return {};
				}),
		]).then(([local, shared]) => {
			return {
				...local,
				...shared,
			};
		});
	},

	pages: {
		'*': ['ui', 'api_cmn', 'api_hotel'],
		'/': ['api_cat'],
		'/login': ['ui', 'auth'],
		'/profile': [],
		'/profile/orders': ['api_order'],
		'/profile/settings': [],
		'/profile/notifications': [],
		'/profile/messages': ['api_order'],
		'/profile/orders/[id]': ['api_order', 'api_hotel_static'],
		'/about': [],
		'/about/video': [],

		'/about/b2b': ['_joinus', '_mobile', '_filter', '_advantages', 'about'],
		'/payment': ['payment'],
		'/payment/success': ['payment', '_filter'],
		'/payment/error': ['payment', '_filter'],
		'/payment/hotel/result': ['payment', '_filter'],
		'/booking/[[...slug]]': [
			'booking',
			'_mobile',
			'_joinus',
			'_filter',
			'api_hotel',
			'api_hotel_static',
			'api_hotel_misc',
			'api_cmn',
			'profile',
		],
		'/booking/new': ['booking', '_mobile', '_joinus', '_filter'],

		'/404': ['ui'],
		'/blog': [],
		'/blog/page/[page]': [],
		'/blog/category/[category]': [],
		'/blog/category/[category]/page/[page]': [],
		'/blog/post/[slug]': [],
		'/blog/tag/[tag]': [],
		'/blog/tag/[tag]/page/[page]': [],
		'/b2b': [],
		'/location/[id]': [],
		'/search': ['api_cat'],
		'/newsearch': ['api_cat'],
		'/activity': [],
		'/restaurant': [],
		'/suppliers': [],
		'/attraction/[id]': ['api_cat'],
		'/hotel/[id]': ['api_cat', 'api_hotel_misc', 'api_hotel_static'],
		'/attraction/[id]/reviews': [],
		'/restaurant/[id]': ['api_cat'],
		'/restaurant/[id]/reviews': [],
		'/activity/[id]': ['api_cat'],
		'/activity/[id]/[offerId]': ['api_cat'],
		'/public_place/[id]': ['api_cat'],

		'/activity/[id]/reviews': [],
		'/supplier/[id]': [],
		'/qr': [],
		'/app': [],

		'rgx:^/knowledge-base': [],
		'/cookie-policy': [],
		'/privacy-policy': [],
		'/offer': [],

		'/list': ['api_cat'],

		'/terms-of-service': [],
		'/user-agreement': [],
		'/data-use': [],
		'/refund-policy': [],

		'/legal-ru/offer': [],
		'/legal-ru/terms-of-service': [],

		'/legal-si/offer': [],
		'/legal-si/terms-of-service': [],

		'/legal-th/offer': [],
		'/legal-th/terms-of-service': [],

		'/article-publishing': [],

		'/content/preview/activity': ['api_cat'],
	},
};
