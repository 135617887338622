import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import type { ApiMethods, ModuleInterceptorsMap, ModuleMocksMap } from './types';

import axios from 'axios';
import MockAdapter from 'axios-mock-adapter';

class Http {
	private readonly instance: AxiosInstance;

	constructor(config: AxiosRequestConfig = {}) {
		this.instance = axios.create(config);

		// base interceptor
		this.instance.interceptors.response.use(
			// eslint-disable-next-line @typescript-eslint/no-unsafe-return
			response => response.data,
			// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
			error => Promise.reject(error.response ?? error)
		);
	}

	public get api(): ApiMethods {
		return {
			get: (url, params, config) => {
				return this.instance.get(url, Object.assign({}, { params }, config));
			},
			post: (url, data, config) => {
				return this.instance.post(url, data, config);
			},
			put: (url, data, config) => {
				return this.instance.put(url, data, config);
			},
			patch: (url, data, config) => {
				return this.instance.patch(url, data, config);
			},
			delete: (url, params, config) => {
				return this.instance.delete(url, Object.assign({}, { params }, config));
			},
		};
	}

	public addInterceptors(modules: ModuleInterceptorsMap) {
		for (const key in modules) {
			const { response, responseError, request, requestError } = modules[key]?.(this.instance);

			this.instance.interceptors.response.use(response, responseError);
			this.instance.interceptors.request.use(request, requestError);
		}
	}

	public addMocks(modules: ModuleMocksMap, config = {}) {
		const mock = new MockAdapter(this.instance, {
			delayResponse: 500,
			onNoMatch: 'passthrough',
			...config,
		});

		for (const key in modules) {
			modules[key]?.(mock);
		}
	}

	public get Instance(): AxiosInstance {
		return this.instance;
	}
}

export default Http;
