import { ConfigProps } from 'antd/lib/notification';
import * as process from 'process';
export * from './nextMiddleware';

export const baseUrl = process.env.NEXT_PUBLIC_BACKEND_URL as string;

export const BLOG_POSTS_PER_PAGE = 5;
export const KNOWLEDGE_BASE_ARTICLES_PER_PAGE = 9;

export const pages = {
	privateRoot: '/activity',

	publicRoot: '/login',
	publicList: ['/login', '/signup', '/forgot_password'],
};

export const cookiesName = {
	authenticated: 'isAuthenticated',
};

export const IS_DEV = process.env.NODE_ENV === 'development';

export const dadataToken = process.env.NEXT_PUBLIC_DADATA_TOKEN;

export const notificationConfig = {
	placement: 'topRight',
	duration: 5,
} as ConfigProps;

export const NEXT_PUBLIC_QVEDO_MAP_CATALOG_HOST =
	(process.env.NEXT_PUBLIC_QVEDO_MAP_CATALOG_HOST as string) || 'https://api-public-dev.qvedo.dev';

export const BRANCH: string = process.env.MAIN_BRANCH?.toString() || 'dev';
export const COMMITHASH: string = process.env.CI_COMMIT_SHA || 'local';
export const NEXT_PUBLIC_LOG_LEVEL = (process.env.NEXT_PUBLIC_LOG_LEVEL as string) || 'info';
export const IS_PROD = process.env.NEXT_PUBLIC_CI_COMMIT_REF_NAME === 'production';

export const NEXT_PUBLIC_QVEDO_MOBILE_SITE_HOST =
	process.env.NEXT_PUBLIC_QVEDO_MOBILE_SITE_HOST || 'https://m-dev.qvedo.dev';

export const NEXT_PUBLIC_QVEDO_ROOT_DOMAIN = process.env.NEXT_PUBLIC_QVEDO_ROOT_DOMAIN || 'localhost';

export const NEXT_PUBLIC_CI_COMMIT_REF_NAME = process.env.NEXT_PUBLIC_CI_COMMIT_REF_NAME || 'local';

export const NEXT_PUBLIC_GOOGLE_CLIENT_ID = process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID || '';
export const NEXT_PUBLIC_APPLE_CLIENT_ID = process.env.NEXT_PUBLIC_APPLE_CLIENT_ID || '';
export const NEXT_PUBLIC_YANDEX_CLIENT_ID = process.env.NEXT_PUBLIC_YANDEX_CLIENT_ID || '';
export const NEXT_PUBLIC_VK_CLIENT_ID = process.env.NEXT_PUBLIC_VK_CLIENT_ID || '';
export const NEXT_PUBLIC_FB_CLIENT_ID = process.env.NEXT_PUBLIC_FB_CLIENT_ID || '';
