import { default as logInterceptor } from '../interceptors/logInterceptor';
import { default as headersInterceptor } from '../interceptors/headersInterceptor';
import Http from '@app/api/http';
import { captureException } from '@sentry/core';
import { ILoginGeo } from '@main/src/types/geo';
import { KEY_LOGIN_GEO } from '@app/constants/localStorage';
import { CURRENT_LOCALE, loginGeo } from '@app/api/browserHttpApi';

export interface IInitAuth {
	checkLoggedIn(): Promise<boolean>;
	refresh(params: Auth2WebRefreshReq): Promise<Auth2WebRefreshResp>;
	logout(): Promise<void>;
	clearUIAuthCookies(): void;
	login(requestParams: Auth2WebLoginReq): Promise<Auth2WebTokenResp>;
	sendPasswordlessCode(requestParams: Auth2WebOnetimeEmailReq): Promise<void>;
	getAuthorizedUserId(): string | null | undefined;
	getUserCountryCode(): string | null | undefined;
	hasAnonymous(): boolean;
	hasEmptyGeo(): boolean;
}

// Dirty hack double refresh
let updatedGeo = false;

export const initAuth = function ({
	baseUrl,
	storageAdapter,
}: {
	baseUrl: string;
	storageAdapter: {
		get: (key: string) => string | undefined | null;
		set: (key: string, value: string) => void;
		remove: (key: string) => void;
	};
}): IInitAuth {
	const axiosInstance = new Http({
		baseURL: baseUrl,
		withCredentials: true,
	});

	axiosInstance.addInterceptors({ logInterceptor: logInterceptor, headersInterceptor: headersInterceptor });

	const webTime = () => {
		const timeObj = time.get();
		if (!isTimeExpired(timeObj.clientTime)) {
			return new Promise(resolve => resolve({}));
		}

		return (
			axiosInstance.api
				.post<Auth2TimeResp>('/auth2/web/time', {})
				.then(timeResp => {
					time.setTime(timeResp.time);
				})
				// eslint-disable-next-line no-console
				.catch(error => console.log('auth error', error))
		);
	};

	const sendPasswordlessCode = async (requestParams: Auth2WebOnetimeEmailReq) => {
		// await webTime();
		await axiosInstance.api.post<{ data: Auth2WebOnetimeEmailReq }>('/auth2/web/onetime-email', requestParams);
	};

	const login = async (requestParams: Auth2WebLoginReq) => {
		return webTime()
			.then(webTimeResult => {
				return axiosInstance.api.post<Auth2WebTokenResp, Auth2WebLoginReq>('/auth2/web/login', requestParams);
			})
			.then(result => {
				time.setExpire(result.expire);
				time.setAnonymous(result.ano);
				time.setUserId(result.usr_id);
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
				time.setUserCountryCode(result.geo.cc);
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				const lat = result.geo.lat6 / 1000000;
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				const lng = result.geo.lon6 / 1000000;
				const loginGeo: ILoginGeo = {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
					cityId: result.geo.city_id,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
					cityName: result.geo.city_name,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
					cc: result.geo.cc,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
					lat6: result.geo.lat6,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
					lon6: result.geo.lon6,
					lat,
					lng,
				};

				window.localStorage.setItem(KEY_LOGIN_GEO, JSON.stringify(loginGeo));
				// TODO remove
				updatedGeo = true;

				return result;
			})
			.catch(error => {
				captureException(error);
				void clearUIAuthCookies();
				throw error;
			});
	};

	const clearUIAuthCookies = () => {
		time.remove();
	};

	const logout = async () => {
		try {
			await axiosInstance.api.post('/auth2/web/logout', {});
			void clearUIAuthCookies();
		} catch (e) {
			captureException(e);
			void clearUIAuthCookies();
			throw e;
		}
	};

	const refresh = async (params: Auth2WebRefreshReq) => {
		return webTime()
			.then(webTimeResult => {
				return axiosInstance.api.post<Auth2WebRefreshResp, object>('/auth2/web/refresh', params);
			})
			.then(result => {
				time.setExpire(result.expire);
				const lat = result.geo.lat6 / 1000000;
				// eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
				const lng = result.geo.lon6 / 1000000;
				const loginGeo: ILoginGeo = {
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
					cityId: result.geo.city_id,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
					cityName: result.geo.city_name,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
					cc: result.geo.cc,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
					lat6: result.geo.lat6,
					// eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
					lon6: result.geo.lon6,
					lat,
					lng,
				};

				window.localStorage.setItem(KEY_LOGIN_GEO, JSON.stringify(loginGeo));
				// TODO remove
				updatedGeo = true;

				return result;
			})
			.catch(error => {
				captureException(error);
				void clearUIAuthCookies();
				throw error;
			});
	};

	function isTimeExpired(clientTime?: number) {
		if (!clientTime) {
			return true;
		}

		const dt1 = new Date();
		const dt2 = new Date(clientTime);
		const diffMinutes = Math.abs(Math.round((dt2.getTime() - dt1.getTime()) / 1000 / 60));

		return diffMinutes > 120;
	}

	const getAuthorizedUserId = () => {
		return time.get()?.userId;
	};

	const getUserCountryCode = () => {
		return time.get()?.userCountryCode;
	};

	function isTokenExpired({
		tokenExpiration,
		clientTime,
		time,
	}: {
		tokenExpiration: number;
		clientTime: number;
		time: number;
	}) {
		const dtNow = new Date();
		const dtTokenExpiration = new Date(tokenExpiration);
		const dtClient = new Date(clientTime);
		const dtTime = new Date(time);
		const diffMinutes = Math.round((dtTokenExpiration.getTime() - dtNow.getTime()) / 1000 / 60);
		const diffMinutesServerClient = Math.round((dtTime.getTime() - dtClient.getTime()) / 1000 / 60);
		return diffMinutes - diffMinutesServerClient < 1;
	}

	const checkLoggedIn = async () => {
		const timeObj = time.get();
		if (!timeObj?.clientTime || !timeObj?.time || !timeObj.tokenExpiration) {
			return false;
		}

		if (
			isTokenExpired({
				tokenExpiration: timeObj.tokenExpiration,
				clientTime: timeObj.clientTime,
				time: timeObj.time,
			})
		) {
			try {
				const result = await refresh({
					lang: CURRENT_LOCALE as LangCodeForUI,
				});
				if (result) {
					return true;
				}

				return false;
			} catch (error) {
				captureException(error);
				void clearUIAuthCookies();
				return false;
			}
		}

		return true;
	};

	const getHasAnonymous = (): boolean => {
		return time.get().ano;
	};

	const EXPIRE_KEY = 'q_time_expire';
	const TIME_KEY = 'q_time';
	const CLIENT_TIME_KEY = 'q_client_time';
	const ANONYMOUS_KEY = 'q_ano';
	const USER_KEY = 'q_u';
	const USER_COUNTRY_CODE = 'q_u_cc';
	const time = {
		get: () => {
			const time = storageAdapter.get(TIME_KEY);
			const clientTime = storageAdapter.get(CLIENT_TIME_KEY);
			const tokenExpiration = storageAdapter.get(EXPIRE_KEY);
			const ano = storageAdapter.get(ANONYMOUS_KEY);
			const userId = storageAdapter.get(USER_KEY);
			const userCountryCode = storageAdapter.get(USER_COUNTRY_CODE);

			const preparedAno = !ano;

			return {
				time: time ? Number(time) : undefined,
				clientTime: clientTime ? Number(clientTime) : undefined,
				tokenExpiration: tokenExpiration ? Number(tokenExpiration) : undefined,
				ano: preparedAno ? preparedAno : ano === 'true',
				userId: userId,
				userCountryCode: userCountryCode,
			};
		},
		setTime: (time: number) => {
			storageAdapter.set(TIME_KEY, time.toString());
			storageAdapter.set(CLIENT_TIME_KEY, Date.now().toString());
		},
		setExpire: (tokenExpiration: number) => {
			storageAdapter.set(EXPIRE_KEY, tokenExpiration.toString());
		},
		setAnonymous: (anonymous: boolean) => {
			storageAdapter.set(ANONYMOUS_KEY, anonymous.toString());
		},
		setUserId: (userId: string) => {
			storageAdapter.set(USER_KEY, userId);
		},
		setUserCountryCode: (userId: string) => {
			storageAdapter.set(USER_COUNTRY_CODE, userId);
		},
		remove: () => {
			storageAdapter.remove(TIME_KEY);
			storageAdapter.remove(EXPIRE_KEY);
			storageAdapter.remove(CLIENT_TIME_KEY);
			storageAdapter.remove(ANONYMOUS_KEY);
			storageAdapter.remove(USER_KEY);
			storageAdapter.remove(USER_COUNTRY_CODE);
		},
	};

	const hasEmptyGeo = () => {
		return Object.keys(loginGeo || {}).length === 0 && !updatedGeo;
	};

	return {
		checkLoggedIn,
		refresh,
		logout,
		clearUIAuthCookies,
		login,
		sendPasswordlessCode,
		getAuthorizedUserId,
		getUserCountryCode,
		hasAnonymous: getHasAnonymous,
		hasEmptyGeo,
	};
};
